@tailwind base;
@tailwind components;
@tailwind utilities;

#aaa{
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#head{
    font-family: 'DM Serif Display', serif;
}

#heads{
    font-family: 'DM Serif Display', serif;
}


#para{
    font-family: 'DM Serif Display', serif;
    font-family: 'Rajdhani', sans-serif !important;
}

#paras{
    font-family: 'DM Serif Display', serif;
    font-family: 'Rajdhani', sans-serif !important;
}


.slick-arrow slick-next{
    color:black !important;
    background: red !important;
}

