@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
*{
  font-family: 'Poppins', sans-serif !important;
}



@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Rajdhani&display=swap');




.slick-arrow{
    background-color: blue !important;
   
}

.video-btn {
    width: 60px ;
    height: 60px;
    
    margin:20px;
    padding: 14px 15px;
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(1, 0, 0, .5) !important;
    text-align: center;
    color: #f8981d;
}

.styles-module_message__Z9MOV {
    max-width: min-content !important;
}



@media (max-width: 1028px) {
    .job {
        display: none !important;
    }
}
@media (max-width: 1028px){
    .form{
        background-image:url('https://intellipaat.com/wp-content/themes/intellipaat/images/counselling-BG.png');
        background-position: center;
        
        }        
    }
 @media (max-width:768px)   {
    .img5{
        display: none;
    }
 }
    
@media (max-width:425px) { 
     .form1{
        padding: 0px 20px 0px;
    }
}
.header1{
    background-image: url("https://pub-87e52a4fd60d4ff081535b01c4bf149b.r2.dev/uploads/2021/08/mainimg2.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.leneargradient{
    background: rgb(2, 0, 36);
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(23, 17, 121, 1) 73%, rgba(145, 7, 190, 1) 100%);
        height: 100%;
        width: 100%;
}

.font_in_contact{
    font-family: "Times New Roman", Times, serif;
}


.grad{
    background: linear-gradient(to top right, white, white,white, #fddced);
    background-color: "rgba(255, 255, 255, 0.25)",
    /* boxShadow: "rgba(157, 157, 157, 0.2) 0px 4px 10px", */
}
.digital_marketing{
    background: linear-gradient(to bottom left, white, white,white, #fddced);
}

.contact_form{
    background: linear-gradient(to top right,#dcedfd, white, white,white, #dcedfd);

}
.footer_grad{
    background: linear-gradient(to bottom , white, white,#dcfdec);
}
.blog_top_left{
    background: linear-gradient(to top right,white, white, white,white, #b99aea);
}

.career_class{
    background: linear-gradient(to top right,white,white,#fddced);
    /* background: linear-gradient(to bottom left,white, white, white,white, #b99aea); */

 
}
.career_formf{
    background: linear-gradient(to top right,#dcedfd, white,white, #dcedfd);

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}